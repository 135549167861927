/* You can add global styles to this file, and also import other style files */
@import 'https://cdn.jsdelivr.net/npm/handsontable/dist/handsontable.full.min.css';

$gutter: 1rem; //for primeflex grid system
@import 'assets/layout/styles/layout/layout.scss';

/* PrimeNG */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';

/* Code Highlight */
@import '../node_modules/prismjs/themes/prism-coy.css';

@font-face {
  font-family: 'ProximaNovaAltRegular';
  src: url('/assets/layout/styles/layout/fonts/ProximaNovaAltRegular.ttf') format('opentype');
}

// DANGER TEXT FOR VALIDATION FIELDS
.text-danger {
  color: red;
}

.border-invalid-input {
  border: 5px solid red;
}

.page-break-avoid {
  page-break-inside: avoid;
}
